import * as Avatar from '@radix-ui/react-avatar';
import { StyleDeclaration, css } from 'aphrodite';
import { styleSheet } from './styles';

export function Avatar2({
	styleDeclaration,
	imgSrc,
	altText,
	fallbackText,
	smallText = false,
}: {
	styleDeclaration?: StyleDeclaration;
	imgSrc?: string;
	altText?: string;
	fallbackText: string;
	smallText?: boolean;
}) {
	return (
		<Avatar.Root className={css(styleSheet.root, styleDeclaration)}>
			{imgSrc ? <Avatar.Image className={css(styleSheet.image)} src={imgSrc} alt={altText} /> : null}
			<Avatar.Fallback className={css(styleSheet.fallback, smallText && styleSheet.smallText)}>
				{fallbackText}
			</Avatar.Fallback>
		</Avatar.Root>
	);
}
