import * as HoverCardPrimitive from '@radix-ui/react-hover-card';
import { css, StyleDeclaration, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { charmGray, white } from '../../styles/colors';

const slideInFromTopNFadeNZoom = {
	'0%': {
		transform: 'translateY(-8px) scale(0.95)',
		opacity: 0,
	},
	'100%': {
		transform: 'translateY(0) scale(1)',
		opacity: 1,
	},
};
const slideInFromBottomNFadeNZoom = {
	'0%': {
		transform: 'translateY(8px) scale(0.95)',
		opacity: 0,
	},
	'100%': {
		transform: 'translateY(0) scale(1)',
		opacity: 1,
	},
};
const slideInFromLeftNFadeNZoom = {
	'0%': {
		transform: 'translateX(-8px) scale(0.95)',
		opacity: 0,
	},
	'100%': {
		transform: 'translateX(0) scale(1)',
		opacity: 1,
	},
};
const slideInFromRightNFadeNZoom = {
	'0%': {
		transform: 'translateX(8px) scale(0.95)',
		opacity: 0,
	},
	'100%': {
		transform: 'translateX(0) scale(1)',
		opacity: 1,
	},
};

const fadeOutZoomOut = {
	'0%': {
		transform: 'scale(1)',
		opacity: 1,
	},
	'100%': {
		transform: 'scale(0.95)',
		opacity: 0,
	},
};

const s = StyleSheet.create({
	content: {
		boxSizing: 'border-box',
		zIndex: 50,
		border: `1px solid ${charmGray}`,
		borderRadius: 8,
		backgroundColor: white,
		padding: '8px 16px',
		boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
		outline: 'none',
		animationDuration: '150ms',
		animationTimingFunction: `cubic-bezier(0.4, 0, 0.2, 1)`,
		':is([data-state="closed"])': {
			animationName: [fadeOutZoomOut],
		},
		':is([data-side="bottom"][data-state="open"])': {
			animationName: slideInFromTopNFadeNZoom,
		},
		':is([data-side="top"][data-state="open"])': {
			animationName: slideInFromBottomNFadeNZoom,
		},
		':is([data-side="left"][data-state="open"])': {
			animationName: slideInFromRightNFadeNZoom,
		},
		':is([data-side="right"][data-state="open"])': {
			animationName: slideInFromLeftNFadeNZoom,
		},
	},
});

const HoverCard = HoverCardPrimitive.Root;

const HoverCardTrigger = HoverCardPrimitive.Trigger;

const HoverCardPortal = HoverCardPrimitive.HoverCardPortal;

const HoverCardContent = React.forwardRef<
	React.ElementRef<typeof HoverCardPrimitive.Content>,
	Exclude<React.ComponentPropsWithoutRef<typeof HoverCardPrimitive.Content>, 'className'> & {
		styles?: StyleDeclaration[];
	}
>(({ styles, align = 'center', sideOffset = 4, ...props }, ref) => {
	return (
		<HoverCardPrimitive.Content
			ref={ref}
			align={align}
			sideOffset={sideOffset}
			className={css(s.content, styles)}
			{...props}
		/>
	);
});
HoverCardContent.displayName = HoverCardPrimitive.Content.displayName;

export { HoverCard, HoverCardContent, HoverCardPortal, HoverCardTrigger };
