import * as React from 'react';

export function ChevronDown(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			strokeLinejoin='round'
			strokeLinecap='round'
			strokeWidth={2}
			stroke='currentColor'
			fill='none'
			viewBox='0 0 24 24'
			width={24}
			height={24}
			{...props}
		>
			<path d='m6 9 6 6 6-6' />
		</svg>
	);
}
